


































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';


@Component
export default class BorderlessCard extends Vue {
  @Prop(String) readonly src: string | undefined;
  @Prop(String) readonly title: string | undefined
  @Prop(String) readonly description: string | undefined
  @Prop({ default: '/', type: String }) navlink!: string
  private ui = vxm.ui;
  //note these dynamic static assets will likely be served from S3

  private publicPath = process.env.VUE_APP_STATIC_DIR;



  private navigate(){
    if (this.navlink)
    this.$router.push(this.navlink);
  }


}
