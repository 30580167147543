










































































import {vxm} from "@/store";
import {Component, Prop, Vue} from 'vue-property-decorator';
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import BorderlessCard from "@/views/viewcomponents/BorderlessCard.vue";
import CaseManagementCard from "@/views/viewcomponents/CaseManagementCard.vue";




@Component({
  components: { WaitModal, CaseManagementCard, BorderlessCard}
})

//the layout for each asset page is the same
//only the content and items differ
//so I think it will also be good to lazy-fetch these instead of cacheing so much data all the time
export default class CaseManagement extends Vue {


  private title: string = "Title";
  private blurb: string = "";
  private showWait: boolean = false;
  private showNew: boolean = false;
  @Prop(String) readonly caseid: string|undefined;
  // private description: any={};
  private ui = vxm.ui;


  mounted(){

  }






}
